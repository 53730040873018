
<script>

import Switches from "vue-switches";
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authHeader } from '../../../helpers/fakebackend/auth-header';


import {
  required,
} from "vuelidate/lib/validators";



/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Pre-buy Vouchers",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
 
    Switches,
    Multiselect,
    Layout,
    PageHeader,
    
  },
  data() {
    return {
      title: "Add Pre-buy Voucher Plan",
      items: [
        {
          text: "Add Pre-buy Voucher Plan",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],


      status :'',
      submitted: false,
      showDismissibleAlert :false,
      loading: false,
      notificationText:"",
      alertvariant:"",
      color:"#A4827D",
      voucherTitle:"",
      shortDesc:"",
      selectTemplate:"1",
      redeemOption:"1",
      sellingPrice:"",
      oneVoucherPrice:"",
      noOfVouchers:"",
      termsOfUse:"",
      validFor:"",
      encodeImage:"",
      imageData:"",
      voucherTypeArr:[{'typeID':1,'name':'Cash Voucher'},
      {'typeID':2,'name':'Product Voucher'}],
      voucherType:"",
      dishesArr:[],
      dishID:"",
      buyVoucher:"",
      freeVouchers:"",
      oneVoucherText:false,
      gstAmount:"",
      sellingPriceWithGST:"",
      dishCGST:"",
      dishIGST : "",
      itemTotal:"",
      discount:"",
      branches:[],
      branchID:"",
      restBranchID:"",
      brandData:[],
      brandArr:[],

       
    };
  },

    validations: {

     validFor:{
         required,
     },
      sellingPrice:{
        required,
      },
       oneVoucherPrice:{
        required:function() {
          //alert(this.posts.ownerID);
             return (this.voucherType && this.voucherType.typeID==1 && this.oneVoucherPrice=="") ? false : true;
        },
      
      },
      // noOfVouchers:{
      //   required,
       
      // },
      voucherTitle:{
        required,
      },
      shortDesc:{
        required,
      },
      voucherType:{
          required
      },
      buyVoucher:{
        required,
      },
      freeVouchers:{
        required,
      },
      dishID:{
        required:function() {
          //alert(this.posts.ownerID);
              return (this.voucherType && this.voucherType.typeID==2 && this.dishID=="") ? false : true;
        },
  
    },
      
    
  },
  methods:{
    previewImage: function(event) {
            // Reference to the DOM input element
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if (input.files && input.files[0]) {
                // create a new FileReader to read this image and convert to base64 format
                var reader = new FileReader();
                // Define a callback function to run, when FileReader finishes its job
                reader.onload = (e) => {
                    // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                    // Read image as base64 and set to imageData
                    this.imageData = e.target.result;
                }
                // Start the reader job - read file as a data url (base64 format)
                reader.readAsDataURL(input.files[0]);
                this.encodeImage = input.files[0];


                
            }
    
  },
  totalVoucher(){
    return Number(this.freeVouchers)+Number(this.buyVoucher) ;
  },

    submitForm(e){

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
         this.loading = true;
          let formData = new FormData();  
           /*
                Add the form data we need to submit
            */  
     
            formData.append('color', this.color);
            formData.append('voucherTitle', this.voucherTitle);
            formData.append('shortDesc',this.shortDesc);
            formData.append('templateType',this.selectTemplate);
            formData.append('sellingPrice',this.sellingPrice);
            formData.append('gstAmount',this.gstAmount);
            formData.append('discount',this.discount);
            formData.append('itemTotal',this.itemTotal);
            formData.append('sellingPriceWithGST',this.sellingPriceWithGST);
            formData.append('oneVoucherPrice',(this.oneVoucherPrice) ? this.oneVoucherPrice :0);
            formData.append('noOfVouchers',this.noOfVouchers);
            formData.append('termsOfUse',this.termsOfUse);
            formData.append('validFor',this.validFor);
            formData.append('document', this.encodeImage);
            formData.append('voucherType',this.voucherType.typeID);
            formData.append('redeemOption',this.redeemOption);
            formData.append('dishID',(this.dishID) ? this.dishID.menuID : 0);
            formData.append('buyVoucher',this.buyVoucher);
            formData.append('freeVouchers',this.freeVouchers);
            formData.append('restBranchID', (this.$storageData.profile.empTypeID==2 || this.$storageData.profile.empTypeID==11) ? this.restBranchID : this.$storageData.profile.restBranchID);
            formData.append('createdBy', this.$storageData.profile.pid);
            formData.append('loginTypeID',this.$storageData.login_type);
            

      e.preventDefault();
      this.axios.post(this.$loggedRole+"/addPreBuyVoucher",  formData,
        {
          headers: authHeader()
        })
      .then((result)=>{
       
        var status = result.data.status;
        if(status==200){
             this.showDismissibleAlert = true;
              this.loading = false;

              setTimeout(() => {
                
                this.$router.push({name: 'preBuyVoucherTable'}); 
                 
              },2000);
        }else{
           alert(result.data.data);
        } 
             
             
      })
     .catch((error)=> {
              this.loading = false;

                         alert(error.response.data.data);
                    });
      }  
    },

      getDishesArr(){
        this.axios.post(this.$loggedRole+"/getDishesByRestBranchID",
          {'restBranchID':this.restBranchID!=""? this.restBranchID: this.$storageData.profile.restBranchID})
          .then((result)=>{
              this.dishesArr = result.data.data;
        });
    },

getDishPrice(){

    this.axios.post(this.$loggedRole+"/getDishPriceByID",
    {'dishID':this.dishID.dishID})
    .then((result)=>{
         
         this.oneVoucherPrice = result.data.data.price;  
         this.dishIGST = result.data.data.IGST;  
         this.dishCGST = result.data.data.CGST;  
         this.calculateSellingPrice(); 
    });
},
changeVoucherType(){
      this.gstAmount= "";
      this.sellingPriceWithGST= "";
      this.dishCGST = "";
      this.dishIGST = "";
      this.sellingPrice = "";
      this.oneVoucherPrice = "";
  if(this.voucherType.typeID==1){
     this.oneVoucherText = false;
  }else{
    this.oneVoucherPrice = "";
     this.oneVoucherText = true;
  }
 
},
calculateSellingPrice(){
  this.voucherTitle = "Buy "+this.buyVoucher+ " and get "+this.freeVouchers+" free";
  this.sellingPrice = Number(this.buyVoucher) * Number(this.oneVoucherPrice);
  this.itemTotal = Number(this.oneVoucherPrice) * (Number(this.buyVoucher) + Number(this.freeVouchers));
  this.discount = "-"+Number(this.oneVoucherPrice) *  Number(this.freeVouchers);

  if(this.voucherType.typeID==1){
    this.gstAmount = 0;
  }else{
   // alert(this.dishCGST);
    let finalGst = ((this.dishCGST) ? ((Number(this.dishCGST)/100*2)) : (Number(this.dishIGST)/100)) * Number(this.sellingPrice); 
    this.gstAmount = finalGst.toFixed(2);
  }
  this.sellingPriceWithGST = Number(this.gstAmount) + Number(this.sellingPrice);
},

getRestaurantBrandsByCompanyID(){
         this.axios
        .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {companyID:this.$storageData.profile.companyID}
        )
        .then((response) => {
                this.brandArr = response.data.data;
                this.brandData = (this.brandArr) ? this.brandArr[0] : "";
                this.readRestaurantBranchData();
                
        }); 
    },

readRestaurantBranchData(){
 
      this.axios
    .post(
      "getRestaurantBranchByRestID", {restID:(this.brandData!="") ? this.brandData.restID : this.$storageData.profile.restID,
      'restBranchID':0,
      'empTypeID':this.$storageData.profile.empTypeID,
      city: (this.city) ? this.city.city : ""}
    )
    .then((response) => {
      this.branches = response.data.data;
      this.branchID = (this.branches) ? this.branches[0] :"";
      this.restBranchID = (this.branchID!="") ? this.branchID.restBranchID: "";
      this.getDishesArr();
      
    });
},

  onchangeBranch(){
        this.restBranchID = this.branchID.restBranchID;
    this.getDishesArr();
     },
  },
   
  mounted(){
  this.getDishesArr();
    if(this.$storageData.profile.empTypeID==2){
        this.readRestaurantBranchData();
    }

    if(this.$storageData.profile.empTypeID==11){
      this.getRestaurantBrandsByCompanyID();
    }
  

  },
  middleware: "authentication",
};
</script>

<template lang="en">
<Layout>
    <PageHeader :title="title" :items="items" />
  <div class="alertMsg">
    <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        Pre-buy Voucher Added Successfully
    </b-alert>
  </div>
 
   
<form @submit.prevent="submitForm" method="post" >
     
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                  <div class="row">
                      <div class="col-md-3 mb-3" v-if="this.$storageData.profile.empTypeID==11">

                         <label>Select Restaurant Brand</label>
                          <multiselect v-model="brandData" :options="brandArr" track-by="restID" label="name"
                          @input="readRestaurantBranchData();" :show-labels="false"></multiselect>
                      </div>

                          <div class="col-md-3 mb-3" v-if="this.$storageData.profile.empTypeID==2 || this.$storageData.profile.empTypeID==11">
                              <label>Select Restaurant Branch* </label>
                                  <multiselect v-model="branchID" :options="branches" track-by="restBranchID" label="branchName"
                                    @input="onchangeBranch();" :show-labels="false"></multiselect>
                          </div>
                  </div>
                  <div class="row"> 
                  <div class="col-md-3 mb-3">
                        <label class="form-label" for="formrow-voucherTitle-input">Voucher Type: *</label>
                          <multiselect v-model="voucherType" :options="voucherTypeArr" track-by="typeID" label="name"
                                @input="changeVoucherType" :show-labels="false"
                                :class="{
                                    'is-invalid': submitted && $v.voucherType.$error,
                                }" > 
                                </multiselect>
                            <div
                                v-if="submitted && $v.voucherType.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.voucherType.required"
                                  >This value is required.</span
                                >
                              </div>
                     
                          
                     </div>


                      <div class="col-md-3 mb-3">
                        <label class="form-label" for="formrow-voucherTitle-input">Buy Voucher: *</label>
                          <div class="input-group">
                          <input type="number"  class="form-control" id="formrow-buyVoucher-input" 
                           v-model="buyVoucher" placeholder="5 (Eg: 5 vouchers)" 
                           @input="calculateSellingPrice"
                           :class="{
                            'is-invalid': submitted && $v.buyVoucher.$error,
                          }">
                          <div class="input-group-text">vouchers</div> 
                          </div>
                            
                        </div>
                          <div
                            v-if="submitted && $v.buyVoucher.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.buyVoucher.required"
                              >This value is required.</span
                            >
                          </div>
                      

                          <div class="col-md-3 mb-3">
                            <label class="form-label" for="formrow-voucherTitle-input">Free Vouchers: *</label>
                            <div class="input-group">
                              <input type="number"  class="form-control" id="formrow-freeVouchers-input" 
                              v-model="freeVouchers" placeholder="1 (Eg: Get 1 voucher free)" @input="calculateSellingPrice"
                              :class="{
                                'is-invalid': submitted && $v.freeVouchers.$error,
                              }">
                               <div class="input-group-text">vouchers</div> 
                          </div>

                            </div>
                              <div
                                v-if="submitted && $v.freeVouchers.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.freeVouchers.required"
                                  >This value is required.</span
                                >
                              </div>
                            
                             <div class="col-md-3 mb-3" v-if="freeVouchers && buyVoucher">
                              <p style="margin-top: 36px;font-weight: 500;color: #A4827D;">
                                User will get total {{totalVoucher()}} vouchers
                                </p>
                            </div>

                     <!-- <div class="col-md-3 mb-3">
                        <label class="form-label" for="formrow-voucherTitle-input">Select No. of Vouchers:*</label>
                          <input type="number"  class="form-control" id="formrow-voucherTitle-input" name="title" 
                          v-model="noOfVouchers" :class="{
                        'is-invalid': submitted && $v.noOfVouchers.$error,
                      }">
                      <div
                          v-if="submitted && $v.noOfVouchers.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.noOfVouchers.required"
                            >This value is required.</span
                          >
                        </div>
                     
                          
                     </div> -->

                   

                </div>
                 <div class="row"> 
                    <div class="col-md-3 mb-3" v-if="voucherType && voucherType.typeID==2">
                        <label class="form-label" for="formrow-voucherTitle-input">Select Product:*</label>
                         <multiselect v-model="dishID" :options="dishesArr" track-by="menuID" label="title"
                               :show-labels="false" 
                                :class="{
                                    'is-invalid': submitted && $v.dishID.$error,
                                }" @input="getDishPrice" > 
                                </multiselect>

                        <div
                          v-if="submitted && $v.dishID.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.dishID.required"
                            >This value is required.</span
                          >
                        </div>
                          
                     </div>

                    <div class="col-md-3 mb-3">
                        <label class="form-label" for="formrow-voucherTitle-input">Single Voucher price:*</label>
                         <div class="input-group"><div class="input-group-text">₹</div> 
                          <input type="number"  class="form-control" id="formrow-voucherTitle-input" name="title" 
                          v-model="oneVoucherPrice" :disabled="oneVoucherText" @input="calculateSellingPrice"
                          :class="{
                        'is-invalid': submitted && $v.oneVoucherPrice.$error,
                      }">
                        </div>

                        <div
                          v-if="submitted && $v.oneVoucherPrice.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.oneVoucherPrice.required"
                            >This value is required.</span
                          >
                        </div>
                          
                     </div>

                      <div class="col-md-2 mb-3">
                        <label class="form-label" for="formrow-itemTotal-input">Item Total</label>
                         <div class="input-group"><div class="input-group-text">₹</div> 
                          <input type="number" :disabled="true" class="form-control" id="formrow-itemTotal-input" name="title" 
                          v-model="itemTotal"
                         >
                        </div>
                     </div>

                      <div class="col-md-2 mb-3">
                        <label class="form-label" for="formrow-discount-input">Discount</label>
                         <div class="input-group"><div class="input-group-text">₹</div> 
                          <input type="number" :disabled="true" class="form-control" id="formrow-discount-input" name="title" 
                          v-model="discount"
                         >
                        </div>
                     </div>

                    

                   



                      </div>

                     

               <div class="row"> 
                  <div class="col-md-2 mb-3">
                        <label class="form-label" for="formrow-voucherTitle-input">Sub Total</label>
                         <div class="input-group"><div class="input-group-text">₹</div> 
                          <input type="number" :disabled="true" class="form-control" id="formrow-voucherTitle-input" name="title" 
                          v-model="sellingPrice"
                         >
                        </div>
                     </div>

                      <div class="col-md-2 mb-3">
                        <label class="form-label" for="formrow-voucherTitle-input">GST Amount</label>
                          <div class="input-group"><div class="input-group-text">₹</div> 
                          <input type="number" :disabled="true" class="form-control" id="formrow-voucherTitle-input" name="title" 
                         v-model="gstAmount"
                         >
                         
                        </div>
                     </div>


                     <div class="col-md-2 mb-3">
                        <label class="form-label" for="formrow-voucherTitle-input">Selling price <span style="font-size: 12px;">(+ GST)</span></label>
                         <div class="input-group"><div class="input-group-text">₹</div> 
                          <input type="number" :disabled="true" class="form-control" id="formrow-voucherTitle-input" name="title" 
                          v-model="sellingPriceWithGST" >
                        </div>
              
                     </div>

                     <div class="col-md-3 mb-3">
                        <label class="form-label" for="formrow-voucherTitle-input">Valid For: *</label>
                         <div class="input-group"> 
                          <input type="number"  class="form-control" id="formrow-validFor-input" 
                           v-model="validFor" :class="{
                            'is-invalid': submitted && $v.validFor.$error,
                          }">
                            <div class="input-group-text">days</div>
                        </div>
                          <div
                            v-if="submitted && $v.validFor.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.validFor.required"
                              >This value is required.</span
                            >
                          </div>
                      </div>


                </div>   
                <div class="row mt-3">
                    <div class="col-md-6">
                    <label class="form-label"> Select Pre-Buy Voucher Template :</label>

                     <b-form-radio
                            v-model="selectTemplate"
                            class="custom-radio mb-3"
                            value="1"
                            plain
                            >Template 1</b-form-radio
                          >
                    <b-form-radio
                   
                        v-model="selectTemplate"
                        class="mb-3"
                        value="2"
                        plain
                        >Template 2</b-form-radio
                    >

                    <b-form-radio
                        v-model="selectTemplate"
                        class="mb-3"
                        value="3"
                        plain
                        >Template 3</b-form-radio
                    >

                    </div>
                     <div class="mb-3 col-md-6">
                       <!-- template 1 -->
                       <div class="preBuyCard col-6">
                            <div class="preBuyTemplate templateType1 card" v-if="selectTemplate==1" :style="{background : color}">
                             
                              <div class="templateBackground" style="height:166px;border-radius:8px;">
                                  <div class="inImage">
                                    <h5 class="preBuyCouponTitle">{{voucherTitle ? voucherTitle : "Title" }}</h5>
                                    <p class="PreCouponDesc">{{shortDesc ? shortDesc : " Short Description" }}</p>
                                      <a href="#" class="btn_1 mb_5 disabledBtn" style="color:#000!important;background:#FFC60C!important;padding:11px 10px;font-size:14px;">Buy Now</a>
                                  </div>

                                   <img class="preBuyImageTempalet1" :src="(imageData)? imageData : $placeholder">
                              </div>
                            </div>
                        </div>
                        <div class="preBuyCard col-6" v-if="selectTemplate==2">
                            <div class="preBuyTemplate templateType2" style="height:166px;">
                              <img class="preBuyImage" :src="(imageData)? imageData : $placeholder" height="166">
                              <div class="inImage">
                              <h5 class="preBuyCouponTitle" style="color:black;">{{voucherTitle ? voucherTitle : "Title" }}</h5>
                                    <p class="PreCouponDesc" style="color:black;">{{shortDesc ? shortDesc : " Short Description" }}</p>
                                              <a href="#" class="btn_1 mb_5 disabledBtn" style="color:#000!important;background:#FFC60C!important;padding:11px 10px;font-size:14px;">Buy Now</a>
                              </div>
                            </div>
                          </div>
                                <!-- <div class="card" v-if="selectTemplate==1" :style="{background : color}">
                                    <div class="card-body" style="height: 270px;" >
                                        
                                                <b class="mb-3">{{voucherTitle ? voucherTitle : "Title" }} </b>
                                                <br><br>
                                                <b class="mb-3">{{shortDesc ? shortDesc : " Short Description" }} </b>

                                                <br><br><br>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <button class="btn btn-themeYellow "> Buy Now </button>
                                                    </div>
                                                
                                                    <div class="image-preview col-md-6">
                                                                <img class="preview" :src="(imageData)? imageData : $placeholder">
                                                    </div>
                                                </div>
                                        </div>  
                                    </div> -->


                                <!-- <div class="" v-if="selectTemplate==2">
                                    <div class="" style="height: 270px; padding:0px" >
                                        <img class="preview" style="height: 264px;width: auto;" :src="(imageData)? imageData : $placeholder">
                                                <b class="mb-3" style="position: absolute;left: 35px;top: 20px;">{{voucherTitle ? voucherTitle : "Title" }} </b>
                                                <br><br>
                                                <b class="mb-3" style="position: absolute;left: 35px;top: 60px;">{{shortDesc ? shortDesc : " Short Description" }} </b>

                                                    <button class="btn btn-themeYellow " style="position: absolute;left: 35px;top: 200px;"> Buy Now </button>
                                                 
                                        </div>  
                                    </div> -->

                                    <div class="preBuyCard col-6" v-if="selectTemplate==3">
                                            <div class="preBuyTemplate templateType3" v-b-modal.modal-couponDetails>
                                              <img class="preBuyImage" :src="(imageData)? imageData : $placeholder" height="166">
                                         
                                            </div>
                                      </div>


                                    <!-- <div v-if="selectTemplate==3">
                                        <div style="height: 270px; padding:0px" >
                                      
                                                    <div class="image-preview col-md-6">
                                                                <img class="preview" style="height: 264px;width: auto;" :src="(imageData)? imageData : $placeholder">
                                                    </div>
                                            
                                        </div>  
                                    </div> -->
                        </div>


                        
                   </div>
               

                <div class="row"> 
                     <div class="mb-3 col-md-6">
                        <label class="form-label" for="formrow-restName-input">Select Image:</label>
                            <div class="file-upload-form">
                                Choose File
                                <input type="file"  class="uploadInput"  @change="previewImage" accept="image/*">
                            </div>
                           
                    </div>

                    <div class="mb-3 col-md-6">
                        <label class="form-label" for="formrow-restName-input">Select Color:</label>
                         <input type="color" class="form-control" v-model="color"
                         style="height: 29px; width: 52px;padding: 3px;">
                           
                    </div>
                </div>

                <div class="row"> 
                     <div class="col-md-6 mb-3">
                        <label class="form-label" for="formrow-voucherTitle-input">Title: *</label>
                          <input type="text" :maxlength="30" class="form-control" id="formrow-voucherTitle-input" name="title" 
                          v-model="voucherTitle" :class="{
                        'is-invalid': submitted && $v.voucherTitle.$error,
                      }">
                     
                            <p style="right: 9px;" v-if="voucherTitle" class="badge position-absolute" :class="{ 'bg-success': voucherTitle.length !== 30,
                            'bg-danger': voucherTitle.length === 30 }">
                                
                                {{ voucherTitle.length }} / 30
                            </p>

                            <div
                              v-if="submitted && $v.voucherTitle.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.voucherTitle.required"
                                >This value is required.</span
                              >
                            </div>
                     </div>

                    <div class="col-md-6 mb-3">
                     <label class="form-label" for="formrow-des-input"> Short Description: *</label>
                        <textarea name="textarea" :maxlength="50"   v-model="shortDesc" rows="3" class="form-control"
                        :class="{
                        'is-invalid': submitted && $v.shortDesc.$error,
                      }" ></textarea>
                         <p style="right: 19px;" v-if="shortDesc" class="badge position-absolute" :class="{ 'bg-success': shortDesc.length !== 50,
                            'bg-danger': shortDesc.length === 50 }">
                                
                                {{ shortDesc.length }} / 50
                            </p>
                        
                        <div
                          v-if="submitted && $v.shortDesc.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.shortDesc.required"
                            >This value is required.</span
                          >
                        </div>
                     </div>
                </div>
                 <div class="col-md-12 mb-3">
                     <label class="form-label" for="formrow-des-input"> Terms of Use:</label>
                        <textarea name="textarea" :maxlength="1000"  v-model="termsOfUse" rows="3" class="form-control" ></textarea>
                         <p style="right: 19px;" v-if="termsOfUse" class="badge position-absolute" :class="{ 'bg-success': termsOfUse.length !== 1000,
                            'bg-danger': termsOfUse.length === 1000 }">
                                
                                {{ termsOfUse.length }} / 1000
                            </p>
                     </div>

                <div class="row mb-3">
                   <label class="form-label"> Redeem Option: </label>
                    <div class="col-md-3">
                           

                            <b-form-radio
                                    v-model="redeemOption"
                                    class="custom-radio mb-3"
                                    value="1"
                                    plain
                                    >Use one voucher per day</b-form-radio
                                  >
                            </b-form-radio>
                    </div>
                    <div class="col-md-3">
                          <b-form-radio
                                  v-model="redeemOption"
                                  class="custom-radio mb-3"
                                  value="2"
                                  plain
                                  >No restriction</b-form-radio
                                >
                          </b-form-radio>
                     </div>
                  
                </div>
              
                <!-- <div class="row"> 
       
                     
                </div> -->

               <div class =" row" style="float:right;">
                 <b-spinner v-if="loading"
                class="m-2 col-3"
                variant="primary"
                role="status"
              ></b-spinner>
              <button type="submit" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
              </div>
              
            </div>
            </div>
           
        </div>


    </div>

</form>


  

</Layout>
</template>

<style lang="scss" scoped>

img.preview {
    width: 100px;
    height: 100px;
    background-color: white;
    border: 1px solid #DDD;
    padding: 5px;
    object-fit: contain;
    margin-top: 6px;
}
.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}

	.preBuyTemplate
	{
		min-width: 310px;
	}

.inImage
	{
		padding: 32px 16px;
		position: absolute;
		top: 0;
	}

.preBuyCouponTitle
	{
		color: #FFF;
		font-size: 17px;
	}
	.PreCouponDesc
	{
		color: #FFF;
;
		font-size: 14px;
	}

.disabledBtn{
    background: #ced4da!important;
  }
  .disabledBtn:hover{
    background: #ced4da!important;
    color:#000;
  }
    .preBuyImageTempalet1
{
    height: 78px;
    width:78px;
		object-fit: contain;
		position: absolute;
	right: 40px;
    bottom: 4px;
}

.preBuyCouponsSection
{
margin-bottom: 25px;
}
.preBuyTitle
{
font-size: 24px;
  color: #212121;
  margin-bottom: 13px;
}
.preBuyCard
{
width: fit-content;
margin-bottom: 20px;
}
.preBuyTemplate
{
min-width: 310px;
}
.preBuyImage
{
object-fit:cover;
min-width: 100%;
border-radius:8px;
}
.templateType1{
      border-radius: 8px;
}
</style>


